window.$RefreshReg$ = () => {};
window.$RefreshSig$ = () => () => {};

window.isInIFrame = top.location != self.location;
if (isInIFrame) {
  document.body.classList.add('iframe');
}

function str_slug (str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}

//redondea un numero
function roundNumber(num, digits, left, right) {
  digits = digits || 3;
  num = parseFloat(num, 10);
  num = (Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(
    digits
  );
  if (Intl) {
    num = new Intl.NumberFormat("es-ES").format(num);
  }
  return left || right ? `${left} ${num} ${right}`.trim() : num;
}

//comparte en facebook
function postToFeed(title, desc, url, image) {
  var obj = {
    method: "feed",
    link: url,
    picture: image,
    name: title,
    description: desc,
  };

  function callback(response) {}
  FB.ui(obj, callback);
}

/**
 * Replace a placehold [data-text]
 * @return {[type]} [description]
 */
const replacePlacehold = (div, data) => {
  const el = $(div);
  Object.entries(data).forEach(([key, value]) => {
    $(`[data-text=${key}]`, el).text(value);
  });
};

var actionBtns = "[type=submit], [data-submit], [data-dismiss]";
function addSpinner(settings) {
  var el = settings.el,
    disable = settings.disabledBtn || true,
    btn = $(actionBtns, el);

  btn.prop("disabled", true);
  if (settings.hideBtn) {
    btn.addClass("d-none");
  }

  //evita doble spinner
  if (!$(".loading").is(":visible")) {
    var container = settings.spinnerAfter
      ? $(settings.spinnerAfter)
      : btn.filter(":focus").last();
    container.after('<div class="text-center loading"><img src="/img/loader.gif" class="loading center-block" width="90px;" style="margin-top: 0px;"></div>');
  }
}

$.ajaxSetup({
  timeout: 18000,
  headers: {
    'X-CSRF-TOKEN': CSRFToken()
  }
});

$(document).ajaxSend(function (e, xhr, settings) {
  //se agrega el spinner
  if (settings.el) {
    addSpinner(settings);
  }

  //muestra los archivos cargados
  if ($(".jFiler-items").length > 0) {
    $("#uploading").removeClass("hide");
  }
});

$(document).ajaxSuccess(function (e, xhr, settings) {
  //si hay mensaje muestra todo de nuevo
  if (xhr.responseJSON && xhr.responseJSON.message) {
    toastr.success(xhr.responseJSON.message);
  }
});

$(document).ajaxError(function (e, xhr, settings, thrownError) {
  var errorString = "Ha ocurrido un error inesperado " + xhr.status,
    data = xhr.responseJSON || {},
    type = "error",
    //error de servidor
    statusMsg = {
      timeout:
        "Tiempo de espera agotado, revisa tu conectividad o vuelve a intentarlo.",
      parsererror:
        "Ha ocurrido un error: Por favor contacta con nosotros. Ref: Parse failed.",
      abort: "Por favor prueba de nuevo.",
    },
    dataMsg = {
      0: "Ha ocurrido un error: Comprueba tu conexión, prueba de nuevo y si no ponte en contacto con nosotros. Ref 0",
      404: "Ha ocurrido un error: Comprueba tu conexión, prueba de nuevo y si no ponte en contacto con nosotros. Ref 404",
      419: "Ha ocurrido un error: Por favor, actualiza la página y vuelve a intentarlo. Ref CSRF",
      //'500': 'Ha ocurrido un error: Por favor, comprueba tu conexión o contacta con nosotros. Ref 500.'
    };

  if (thrownError == "timeout") {
    errorString =
      "Existen problemas con la conexión, por favor intenta de nuevo. Ref timeout";
  } else if (statusMsg[thrownError]) {
    errorString = statusMsg[thrownError];
  } else if (dataMsg[xhr.status]) {
    errorString = dataMsg[xhr.status];
  } else if (xhr.status == 401 && !data.reload) {
    showLoginModal("check");
    errorString = "";
  } else if (typeof data.errors === "string" || data.errors instanceof String) {
    errorString = data.errors;
  } else if (typeof data.errors == "object") {
    errorString = '<ul class="ulclean">';
    $.each(data.errors, function (key, value) {
      errorString += "<li>" + value + "</li>";
    });
    errorString += "</ul>";
  } else if (
    typeof data.message === "string" ||
    data.message instanceof String
  ) {
    errorString = data.message;
  } else if (typeof data.message == "object") {
    errorString = '<ul class="ulclean">';
    $.each(data.message, function (key, value) {
      errorString += "<li>" + value + "</li>";
      //para hacer highlight
      keyclean = key.replace(".", "");
      $("#" + keyclean).addClass("has-error");
    });
    errorString += "</ul>";
  } else if (data.error) {
    errorString = data.error;
  } else if (data.errorMessage) {
    errorString = "";
  } else {
    errorString =
      "Ha ocurrido un error.\n Ref. " + (data.status || xhr.statusText);
  }

  if (console[type]) {
    console[type](errorString, settings);
  }

  if (errorString.length > 0 && toastr[type]) {
    toastr[type](errorString);
  }
});

$(document).ajaxComplete(function (event, xhr, settings) {
  //va a redireccionar o no va a restaurar
  if (
    (xhr.status == 200 && xhr.responseJSON && xhr.responseJSON.url) ||
    settings.noRestore
  ) {
    return true;
  }

  if (settings.el && !settings.hideButton) {
    var el = $(settings.el),
      btn = $(actionBtns, el);
    btn.prop("disabled", false);

    if (settings.hideBtn) {
      btn.removeClass("d-none");
    }
  }

  if (settings.scrollTo) {
    var scrollEl = $(settings.scrollTo);
    $("html, body").animate(
      {
        scrollTop: Math.max(scrollEl.offset().top - 20, 0),
      },
      300
    );
  }

  if (!settings.noRemove) {
    $(".loading").remove();
  }

  if (typeof settings.afterComplete === "function") {
    settings.afterComplete();
  }
});

/**
 * Establece o lee el CSRF
 * @param {[type]} content [description]
 */
function CSRFToken(content) {
  var meta = $('meta[name="csrf_token"]');
  return content ? meta.attr("content", content) : meta.attr("content");
}

(function toastrconfig() {
  //$.notify.defaults({position: 'top',  globalPosition: 'top left',  autoHideDelay: 8000})
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "8000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  if (screen.width > 300) {
    toastr.options.positionClass = "toast-top-center";
  } else {
    toastr.options.positionClass = "toast-top-full-width";
  }
})();

//esto se usa para social events y para el panel admin creo
(function () {
  

  window.shadowEmail = function (email) {
    return email.replace(/(?!^).(?=[^@]+@)/g, "*");
  };

  window.currencyFormat = function (e, currency) {
    return parseFloat(e || 0).toFixed(2)
      + (currency ? ` ${currency}` : '');
  };

  window.dateFullFormat = function (data) {
    return moment(data).isValid()
      ? moment(data).format("DD MMM YY [-] HH:mm[h]", "es")
      : "";
  };

  window.fEncodeURIComponent = function (str) {
    return encodeURIComponent(str)
      .replace(/[!'()]/g, escape)
      .replace(/\*/g, "%2A");
  };

  const cache = {};
  window.tmpl = function tmpl(str, data) {
  // Figure out if we're getting a template, or if we need to
  // load the template - and be sure to cache the result.
  if (!/\W/.test(str)) {
    let el = document.getElementById(str);
    if (!el) {
      throw new Error(`No existe la plantilla ${str}`);
    }
    str = el.innerHTML;
    return tmpl(str, data);
  }

  cache[str] = cache[str] ||
      // Generate a reusable function that will serve as a template
      // generator (and which will be cached).
        new Function(
          "obj",
          "var p=[],print=function tmplGen(){p.push.apply(p,arguments);};" +
            // Introduce the data as local variables using with(){}
            "with(obj){p.push('" +
            // Convert the template into pure JavaScript
            str
              .replace(/[\r\t\n]/g, " ")
              .split("<%")
              .join("\t")
              .replace(/((^|%>)[^\t]*)'/g, "$1\r")
              .replace(/\t=(.*?)%>/g, "',$1,'")
              .split("\t")
              .join("');")
              .split("%>")
              .join("p.push('")
              .split("\r")
              .join("\\'") +
            "');}return p.join('');"
        );
    const fn = cache[str];
    // Provide some basic currying to the user
    return data ? fn(data) : fn;
  };
})();

//paste this code under head tag or in a seperate js file.
// Wait for window load

(() =>{
  const removeLoader = () => {
    // Animate loader off screen
    $(".se-pre-con").fadeOut("slow");
    $('body').addClass('loaded');
  }
  //Aunque no esté listo a los 7 segundos se quita el loader
  setTimeout(function () {
    removeLoader();
  }, 7000);

  $(window).on("load", function () {
    removeLoader();
  });
})();

$(window).on("load", function () {
  $('[data-toggle="tooltip"]').tooltip(); //tooltips on hover
  $("#cookieWarnBox").addClass("hidden-print");

  //get hash code at next page
  var hashcode = window.location.hash;

  if (hashcode == "#contact") {
    //move page to any specific position of next page(let that is div with id "hashcode")
    //$("html, body").scrollTop($(hashcode).position().top);
    $("html,body").animate(
      {
        scrollTop: $(hashcode).offset().top,
      },
      "slow"
    );
  } else {
    $("html, body").scrollTop(0);
  }
});

// Esto  se usa en master mas no se exactamente como
var buttonpressed;
$(".submitBtn").click(function () {
  buttonpressed = $(this).attr("name");
});

//hide hotjar
$(function () {
  setTimeout(function () {
    $('*[data-minimized-position="bottom_right"]').addClass("hidden-print");
  }, 2000);
});

//lleva al tope
$("#goTop").on("click", function (e) {
  $("html, body").animate(
    {
      scrollTop: 0,
    },
    500
  );
});

// ajusta el footer
$(function () {
  var docHeight = $(window).height();
  var footerHeight = $("footer").height();
  var content = $("#wrapper").height();
  var nav = $("#mainNav").height();
  var diff = docHeight - footerHeight - nav - 50;

  if (content < diff) {
    $("#wrapper").css("min-height", diff + "px");
  }
});

function showLoginModal(tab) {
  var modal = $("#loginModal");
  var func = {
    check: goToCheck,
    login: goToLogin,
    register: goToRegister,
    recovery: goToRecovery,
    cookies: goToCookies,
  };

  if (!modal.data("fbloaded")) {
    loadFb();
    modal.data("fbloaded", 1);
  }

  tab = tab || "check";
  if (func[tab]) {
    func[tab]();
  }

  //restaura las opciones de facebook
  $(".fb-step1").removeClass("hide");
  modal.modal("show");
}

//copy to clipboard
$(document).on("click", "[data-action=copy]", function (e) {
  e.preventDefault();
  /* Create input */
  $("[data-action=copy]").after(
    '<input class="" value="' +
      $(this).attr("data-url") +
      '" id="share-url" style="position: absolute; left: -1000px; top: -1000px">'
  );
  /* Get the text field */
  var copyText = document.getElementById("share-url");
  /* Select the text field */
  copyText.select();
  /* Copy the text inside the text field */
  document.execCommand("copy");
  /* Alert the copied text */
  toastr.success("Link copiado a tu sistema, pégalo donde quieras");
});
$(document).on('click','[data-action=copy-modal]', function (e){
  // e.preventDefault();
  var input = document.createElement('textarea');
  // let otherText = document.querySelector("#shared-url");
  let otherText = $(this).attr("data-url");
  input.innerText = otherText;
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  input.remove();
  toastr.success("Link copiado a tu sistema, pégalo donde quieras");
});

//por alguna extraña raon se dispara el evento al primer lik cuando no deberia.
window.historyCounter = 0;
//maneja los modales para el back
$(".modal").on("shown.bs.modal", function () {
  // any time a modal is shown
  var $this = $(this),
    changeState = !$this.data("change-state"),
    urlReplace = window.location.pathname + "#" + $this.attr("id");
  if (changeState) {
    // make the hash the id of the modal shown
    history.pushState(
      {
        modal: $this.attr("id"),
      },
      document.title,
      urlReplace
    ); // push state that hash into the url
  }
});

// If a pushstate has previously happened and the back button is clicked, hide any modals.
$(window).on("popstate", function (e) {
  window.historyCounter++;
  if (window.historyCounter == 1) {
    return true;
  }
  $(".modal").modal("hide");
});

$(".modal").on("hidden.bs.modal", function (e) {
  var $this = $(this);
  if (window.location.hash == "#" + $this.attr("id")) {
    //clean URL
    history.pushState("", document.title, window.location.pathname);
  }
});

(function ($) {
  "use strict"; // Start of use strict
  // jQuery for page scrolling feature - requires jQuery Easing plugin
  //to progressively do to an anchor
  // Highlight the top nav as scrolling occurs
  // $('body').scrollspy({
  //   target: '.navbar-fixed-top',
  //   offset: 51
  // })
  // var scrollSpy = new bootstrap.ScrollSpy(document.body, {
  //   target: "#navbar-vivetix",
  // });

  // Closes the Responsive Menu on Menu Item Click
  $(".navbar-collapse ul li a").click(function () {
    $(".navbar-toggle:visible").click();
  });

  //para minimizar incompatibilidades con vh (viewport horizontal)
  var clientHeight = $(window).height();
  $(".img-container").css("height", clientHeight);
})(jQuery); // End of use strict

(function () {
  //ajusta el navbar
  $(window).on("resize load orientationchange", function () {
    $("#mainNav").css("max-width", $("body").width());
  });

  $(".formcontact [data-wa]").on("click", function () {
    $(".formcontact [name=wa]").val($(this).data("wa"));
  });

  $(".formcontact").submit(function (e) {
    var $this = $(this),
      data = $(this).serialize(),
      $accept = $('[name="accept"]', $this);
    e.preventDefault();
    if (!$accept.is(":checked")) {
      toastr.error("Debes haber leído la politica de privacidad");
      return true;
    }
    $.ajax({
      dataType: "json",
      url: $this.attr("action"),
      data: data,
      method: "POST",
      el: $this,
      spinnerAfter: $(this).find(".btns"),
    })
      .done(function (data) {
        $("#contact").modal("hide");
        if ($(".formcontact [name=wa]").val() == "1") {
          var email = $this.find('input[name="email"]').val(),
            message = $this.find('textarea[name="message"]').val(),
            win = window.open(
              `https://api.whatsapp.com/send?phone=${window.contact}&text=${email}:${message}`,
              "_blank"
            );
          if (win) {
            //Browser has allowed it to be opened
            win.focus();
          } else {
            //Browser has blocked it
            toastr.warning(
              "Por favor permite las ventanas emergentes para esta opción"
            );
          }
        }
      });
  });

  function addStyle(href) {
    var styles = document.createElement("link");
    styles.rel = "stylesheet";
    styles.type = "text/css";
    styles.media = "screen";
    styles.href = href;
    document.getElementsByTagName("head")[0].appendChild(styles);
  }

  jQuery(window).on("load", function () {
    if (window.location.hash == "#loginModal") {
      if (isLogin) {
        window.location.hash = "";
      } else {
        showLoginModal();
      }
    } else if (window.location.hash == "#recoverModal") {
      showLoginModal("recovery");
    } else if (window.location.hash == "#contact") {
      $("#contact").modal("show");
    }
  });

  document.addEventListener("DOMContentLoaded", function (event) {
    setTimeout(function () {
      for (var i in delayCSS) {
        addStyle(delayCSS[i]);
      }
    }, 100);

    //lazy image
    var lazyloadImages = document.querySelectorAll("img[data-src], iframe[data-src]");
    var lazyloadThrottleTimeout;

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
          if (img.offsetTop < window.innerHeight + scrollTop) {
            img.src = img.dataset.src;
            img.classList.remove("lazy");
            img.classList.add("loaded");
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
    window.addEventListener("load", function () {
      setTimeout(function () {
        lazyload();
      }, 2000);
    });
  });
})();

$(function() {
  'use strict';
  var width = $(window).width();
    
  function readMore() {
    // if($(this).width() > 750){
    //   return true;
    // }
    var desc = $('#descriptionsEvent'),
      readBtn = $("#leermaslink"),
      h, //altura actual
      max; //maxima altura;
    //reset div
    desc.removeClass('short shadow').css('height', 'auto');
    //height without class
    h = desc.height();
    max = 310;

    if (h > max) {
      desc.addClass('short shadow');
      desc.css('height', max);
      readBtn.removeClass('d-none');
    } else {
      readBtn.addClass('d-none');
    }

    readBtn.off('click');
    readBtn.on('click', function(e) {
      e.preventDefault();

      $('html, body').animate({
        scrollTop: desc.offset().top
      }, 200);

      if (desc.hasClass('shadow')) {
        desc.animate({
          height: h + 'px'
        }, 100, function() {
          desc.removeClass('shadow').addClass('long');
          readBtn.addClass('long');
        });

      } else {
        desc.animate({
          height: max
        }, 100, function() {
          desc.addClass('shadow').removeClass('long');
          readBtn.removeClass('long');
        });
      }
    });
  }

  $(window).on('load', readMore);

  $(window).on('resize', function() {
    if ($(this).width() != width) {
      width = $(this).width();
      readMore();
    }
  })
});

// avisa a parent que cambio de tamanho
const resizeCb = () => {
  const { body } = document;
  const html = document.documentElement;

  const height = Math.max(
    body.offsetHeight,
    html.offsetHeight,
  );

  window.parent && window.parent.postMessage({
    vivetix: 1,
    height,
  }, '*');
};

const resize_ob = new ResizeObserver(resizeCb);
// start observing for resize
resize_ob.observe(document.querySelector("body"));

window.addEventListener('load', resizeCb, false);
window.addEventListener('resize', resizeCb, false);

window.fNumber = function (num, type) {
  let n = parseFloat(num);
  type = type || 'decimal';
  if (type == '%') {
    type = 'percent';
    n = n / 100;
  }
  const options = {
    style: type,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  
  return n.toLocaleString(navigator.language, options);
}

function showLoader() {
  var loader = document.querySelector('.se-pre-con');
  if (loader) {
    loader.style.display = 'block';
  }
}

function hideLoader() {
  var loader = document.querySelector('.se-pre-con');
  if (loader) {
    loader.style.display = 'none';
  }
}

