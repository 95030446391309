var hjLoaded = false;
var gaLoaded = false;
function inIframe () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

var loadHotjar = function ()
{
  if (!hjLoaded) {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:549427,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    hjLoaded = true;
  }
}

var loadGA = function (){
  if(gaLoaded) return;
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  ga('set', 'anonymizeIp', true);
  ga('create', g.code, 'auto');
  if(window.extraGA) extraGA();
  ga('send', 'pageview');
  ga('userTracker.send', 'pageview');
  ga('set', 'userId', g.user);
  gaLoaded = true;
}

function activeCookies(c)
{
  if(c.a){
    loadHotjar();
    loadGA();
  }
  
  if(c.m){
    // solo depues de cargar la pagina para que cargue el page view
    window.addEventListener('load', () => {
      if (typeof fbq !== 'undefined' && fbq) {
        fbq('consent', 'grant');
      }
    });
  }
}

jQuery(function($) {
  function checkCookie_eu()
  {
    var consent = getCookie_eu("cookies_consent_");
    if (consent === null || consent === "" || consent === undefined)
    {
      if(!inIframe()){
        $('#cookie_directive_container').modal('show');
      }
    }else if(consent){
      activeCookies(consent);
    }
  }

  function setCookie_eu(c_name,value,exdays)
  {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(JSON.stringify(value)) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
    document.cookie = c_name + "=" + c_value+"; path=/";
    activeCookies(value);
    $('#cookie_directive_container').modal('hide');
  }

  function getCookie_eu(c_name)
  {
    var i,x,y,ARRcookies=document.cookie.split(";");
    for (i=0;i<ARRcookies.length;i++)
    {
      x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
      y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
      x=x.replace(/^\s+|\s+$/g,"");
      if (x==c_name) {
          return JSON.parse(unescape(y));
      }
    }
  }

  var acceptBtn = $("#cookie_directive_container button.accept");
  var custom = false;
  $('#cookie_directive_container #customConsent').on('show.bs.collapse', function () {
    var text = acceptBtn.data('custom');
    custom = true;
    acceptBtn.text(text);
  });

  $('#cookie_directive_container #customConsent').on('hide.bs.collapse', function () {
    var text = acceptBtn.data('normal');
    custom = false;
    acceptBtn.text(text);
  });

  $("#cookie_directive_container button.accept").click(function(){
    var $this = $(this).parents('.modal-dialog'),
      val = $this.find('input[type=checkbox]:checked'),
      v = {};
    val.each(function(i, e){
      var elem = $(e), name = elem.attr('name');
      if(!name) return;
      v[name] = 1;
    });

    if(!custom){
        v = {a:1, m: 1};
    }
    setCookie_eu("cookies_consent_", v, 60);
  });
  checkCookie_eu();
});