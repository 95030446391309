/********************
//Login
********************/

(function () {
  $.validate({
    form: "#form-login, #form-register, #form-check",
    lang: "es",
    validateOnBlur: true,
  });

  const url = document.location.href,
    //tabs
    registerTab = document.querySelector("#registertab"),
    checkTab = document.querySelector("#checktab"),
    loginTab = document.querySelector("#logintab"),
    recoveryTab = document.querySelector("#recoverytab"),
    fbchecksTab = document.querySelector("#fbcheckstab"),
    fbavatarTab = $("#fbavatartab");

  function setStateForm(form, active) {
    $("select, input, textearea", form).attr("disabled", !active);
  }

  window.goToCookies = () => {
    var email = window.localStorage && localStorage.getItem("email");
    if (email) {
      $("#form-login input[name=email]").val(email);
      goToLogin();
    } else {
      goToCheck();
    }
  };

  window.goToRegister = () => {
    // registerTab.tab("show");
    var showRegister = new bootstrap.Tab(registerTab);
    showRegister.show();
  };

  window.goToCheck = function () {
    var showCheck = new bootstrap.Tab(checkTab);
    showCheck.show();
  };

  window.goToLogin = function () {
    if (loginTab) {
      var showLogin = new bootstrap.Tab(loginTab);
      showLogin.show();
    }
  };

  window.goToRecovery = function () {
    var showRecovery = new bootstrap.Tab(recoveryTab);
    showRecovery.show();
  };

  window.goToFbChecks = function () {
    var showFbChecks = new bootstrap.Tab(fbchecksTab);
    showFbChecks.show();
  };

  //sigue las pestañas y activa y desactiva los formularios
  $('.nav a[data-toggle="tab"]').on("show.bs.tab", function (e) {
    var forms = $("#register, #login, #recovery");
    setStateForm(forms, false);
    var f = $($(e.target).attr("href"));
    setStateForm(f, true);
  });

  //ir a recuperar contraseña
  $("#link-recovery").click(function (e) {
    e.preventDefault();
    $("#form-login").validate({
      ignore: "#passwordLogin",
    });

    //desactiva los formularios que no se usen (log)
    goToRecovery();
    $("#recover-email").val($("#email-login-normal").val());
  });

  //boton de flecha atras
  $('#link-create, #link-change, #contentAuthUser a[data-dismiss="modal"]').click(function () {
    goToCheck();
  });
})();

/*CHECK */
var checkEmail = function (e) {
  e.preventDefault();
  var $this = $(this),
    data = $this.serializeArray();
  $.ajax({
    url: $this.attr('action'),
    data: data,
    method: "POST",
    retryCount: 0, // start retry count
    retryLimit: 1, //will let you retry a determined number of times
    el: $this, //for spinner
    disabledBtn: true,
    spinnerAfter: $("#email-check-container"),
  }).done(function (data) {
    $('#disabled-user').addClass('d-none');
    if (data.goto == "login") {
      $("#form-login input[name=email]").val(data.email);
      //ajusta el tamaño del email input
      var len = $("#form-login input[name=email]").val().length;
      $("#form-login input[name=remember]").prop(
        "checked",
        data.remember != null
      );
      goToLogin();
    } else if (data.goto == "register") {
      $("#emailValue").text(data.email);
      $("#form-register input[name=email]").val(data.email);
      //ajusta el tamaño del email input
      $("#form-register input[name=remember]").val(data.remember);
      goToRegister();
    }
  }).fail(function (resp) {
    const data = resp.responseJSON;

    if (data.disabled) {
      $('#error-text-check').html(data.errorMessage);
      $('#error-message-check').removeClass('d-none');
    }
  });
};

/* REGISTER & LOGIN */
var onSubmit = function (e) {
  var $this = $(this);
  e.preventDefault();

  var data = $this.serializeArray();
  data.push({
    name: "_url",
    value: window.location.pathname,
  });

  if (window.opener) {
    data.push({
      name: "_popup",
      value: +!!window.opener,
    });
  }

  $.ajax({
    url: $this.attr('action'),
    data: data,
    method: "POST",
    timeout: 10000, // sets timeout to 5000 = 5 seconds
    retryCount: 0, // start retry count
    retryLimit: 1, //will let you retry a determined number of times
    el: $this, //for spinner
    spinnerAfter: $this,
  }).done(function (data) {
    const ajax = this;
    //guardo el email en el storage
    if (!data.facebook) window.localStorage.setItem("email", data.email);
    if (data.url) {
      this.noRestore = true;
      window.location.href = data.url;
    } else {
      const event = new CustomEvent('logged', {"bubbles":true, "cancelable":true, "detail": data});
      document.dispatchEvent(event);
      CSRFToken(data.CSRF_Token);
      $(".user-profile-photo").css(
        "background-image",
        "url(" + data.url_image + ")"
      );
      $(".user-info .user-name").text(data.name);
      $("#mainNav").addClass(data.classes);
      if (!event.defaultPrevented) {
        $("#loginModal").modal("hide");
      } else {
        this.noRestore = true;
      }
    }
  }).fail(function (xhr, textStatus, errorThrown) {
    const data = xhr.responseJSON;
    $("#recover-email").val($("#email-login-normal").val());

    if (data.disabled) {
      goToCheck();
    }
  });
};

/* RECOVER PASSW */
$("#form-recover").on("submit", function (e) {
  var $this = $(this);
  e.preventDefault();
  var data = $this.serialize();

  $.ajax({
    url: route("recovery-password"),
    data: data,
    method: "POST",
    timeout: 10000, // sets timeout to 5000 = 5 seconds
    retryCount: 0, // start retry count
    retryLimit: 1, //will let you retry a determined number
    el: $this,
  }).done(function (data) {
    goToLogin();
  });
});

/*FACEBOOK CHECKS*/
function loadFb() {
  window.fbAsyncInit = function () {
    FB.init({
      appId: "1848149522068139",
      xfbml: true,
      version: "v3.2",
      autoLogAppEvents: true,
      cookie: true,
    });
    FB.AppEvents.logPageView();
    fbloaded();
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.async = true;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
}

/*Load facebook*/
function fbloaded() {
  $(".fb-login").click(function (e) {
    e.preventDefault();
    var form = $("#form-facebook");
    form.attr("novalidate", "novalidate");
    goToFbChecks();
    FB.login(
      function (response) {
        if (login(response)) validarUsuario(form);
      },
      {
        scope: "public_profile, email",
      }
    );
  });
}

function validarUsuario(form) {
  var extra = $(".extra");
  extra.html("");

  FB.getLoginStatus(function (response) {
    console.info("Respuesta", response);
    if (response.status == "connected") {
      var token = response.authResponse.accessToken;
      console.info("Access Token", token);
      $("<input>", {
        type: "hidden",
        name: "tokenfb",
        value: token,
      }).appendTo(extra);
      emailVerif(token, form);
    } else if (response.status == "not_authorized") {
      fbError(
        "Tienes que autorizarnos, si no por favor haz login con tu email y contraseña"
      );
    } else {
      fbError("Tienes que ingresar tu cuenta de Facebook");
    }
  });
}

// TODO
//error facebook
function fbError(msg) {
  $(".btn").removeClass("hide");
  $(".loading").remove();
  goToCheck();
  toastr.error(msg);
}

function emailVerif(id, form) {
  var formData = "tokenfb=" + id;
  $.ajax({
    url: route("fb-email"),
    data: formData,
    method: "POST",
    timeout: 20000, // sets timeout to 5000 = 5 seconds
    el: form,
  }).done(function (data) {
    var html = tmpl("tpl_fb_avatar", data);
    //coloca el avatar y el nombre
    $(".fb-info-avatar").html(html).fadeIn();
    //esta registrado
    if (data.register) {
      form.submit();
      $(".noavatar").addClass("hide");
      return true;
    }
    $(".noavatar").removeClass("hide");
    $("input[name=email]").val(data.email);
  });
}

function login(response) {
  if (!response.authResponse) {
    fbError(
      "Hay algún problema con el acceso a Facebook, puede ser que tengas algún bloqueador. Prueba a introducir tu email."
    );
    return false;
  }
  return true;
}

$(function () {
  //click on suggestion
  $("button.help").on("click", function () {
    var $this = $(this),
      block = $("[type=email]", $this.parents(".row")),
      help = $(".help-block", block.parent());
    help.hide();
    block.val($this.text());
  });

  let emailField = $("#form-check [type=email]");
  var suggestion = () => {
    const $this = emailField,
      block = $(".help-block", $this.parent());
    $this.mailcheck({
      suggested: function (element, suggestion) {
        block.show().find("button.help").text(suggestion.full);
      },
      empty: function (element) {
        block.hide();
      },
    });
  };

  /**Pra esperar que el usuario deje de teclear y mosrarle sugerencias*/
  let counter = null;
  emailField.on("keyup", () => {
    counter = setTimeout(() => {
      suggestion();
    }, 500);
  });

  emailField.on("keydown", () => {
    counter && clearTimeout(counter);
  });

  emailField.on("blur change", suggestion);
});

//bind
$(function () {
  $("#form-login, #form-register, #form-facebook").on("submit", onSubmit);
  $("#form-check").on("submit", checkEmail);
  $('#btn-link-login').on("click", function() {
    const $this = $(this);
    const email = $('#email-login-normal').val();
    $.ajax({
      url: route('link-login'),
      data: { email },
      method: "POST",
      retryCount: 0, // start retry count
      retryLimit: 1, //will let you retry a determined number of times
      el: $this, //for spinner
      spinnerAfter: $("#email-check-container"),
      hideBtn: true,
    }).done(function (data) {
      toastr.success(data.message);
    });
  });
});
